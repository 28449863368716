<script>
import { Line } from 'vue-chartjs'
// import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: Line,
  props: { 
    options: {
      type: Object
    },
    chartData: {
      type: Object
    },
    update: {
      type: Number
    }
  },
  watch: {
    'update' (to, from) { 
      this.renderChart(this.chartData, this.options)
    }
  },
  beforemounted() {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartData, this.options)
  },
  data () {
      return { 
        
        
      }
    },
}
</script>
