<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <div :style="cardBg" class="panel panel-default">
        <div class="dropdown" v-if="type === 'card-chart' || type=='single-view' || component=='piechart'">
          <button style="position: relative; float: right; background-color: white;" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <li class="fa fa-cog"></li>
          </button>
          <div class="dropdown-menu" style="right: 0; left: auto; min-width: auto;" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click="setDuration(1)">Daily</a>
            <a class="dropdown-item" href="#" @click="setDuration(2)">Weekly</a>
            <a class="dropdown-item" href="#" @click="setDuration(3)">Monthly</a>
          </div>
        </div>
        <div style="padding: 20px; display:block;" @click="goToView">
          <div >
            <div style="margin-bottom: -25px" v-if="type === 'card-chart'">
              <h1 style="margin-top: 4px; margin-bottom:0px;">{{ value }}</h1>
              {{ name }} 
            </div>
            <div v-else-if="component!='piechart'" >
              <h1 style="margin-top: 4px; margin-bottom:0px;">{{ value }}</h1>
              {{ name }} 
            </div>
            <div style="" v-if="component=='piechart'">
              <h1 style="margin-top: 4px; margin-bottom:0px;">{{ value }}</h1>
            </div>
            <div v-if="!showPiechart">
              
                <component :is="charttype"  :styles="chartstyle" v-bind:chartData="chartdata" v-bind:options="options"
                  v-if="type === 'card-chart'"
                />
                <component :is="charttype"  :styles="chartstyle" v-bind:chartData="chartdata" v-bind:options="chartoptions"
                  v-if="type === 'single-view'"
                />
                
            </div>        
            <div v-else>
              
                <component :is="charttype"
                  :styles="chartstyle"
                  v-bind:chartData="chartdata"
                  v-bind:options="optionsPie"
                  v-bind:update="update"
                />
                
            </div>
                    
          </div>
          <!-- <div><i :class="icon" style="font-size:24px;"/></div> -->
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import DynamicChartLine from '@/components/common/Dashbord/DynamicChartLine'
import DynamicChartBar from '@/components/common/Dashbord/DynamicChartBar'
import DynamicChartDougnut from '@/components/common/Dashbord/DynamicChartDougnut.vue'
export default {
  props: {
    name: {
      type: String
    },
    value: {
      type: String
    },
    icon: {
      type: String
    },
    cardBg: {
      type: String
    },
    type: {
      type: String
    },
    charttype: {
      type: String
    },
    component: {
      type: String
    },
    chartdata: {
      type: Object
    },
    styleChart: {
      type: String,
      default: ""
    },
    update: {
      type: Number,
      default: 0
    },
    optionsPie: {
      type: Object
    },
  },
  components:{
    DynamicChartLine,
    DynamicChartBar,
    DynamicChartDougnut
  },
   data: function () {
      return {
        showPiechart:false,
        chartoptions: {
          responsive: true,
          maintainAspectRatio: false, 
          legend: {
              display: false
          }
        },
        chartstyle: {
                    height: `80px`,
                    width: `auto`,
                    // position: 'relative'
                  },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
           callbacks: {
                label: function(tooltipItem, data) {
                    var label = '';

                    if (label) {
                        label += ': ';
                    }
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    return label;
                }
            }
        },
          legend: {
            display: false,
          },
          layout: {
              padding: {
                top: 25,
                right: 5
              }
          },
          scales: {
            display:false,
            xAxes: [{
              gridLines: {
                display: false,
                drawBorder: false
              },
              ticks: {
                display: false
              }
            }],
            yAxes: [{
              ticks: {
                display: false,
                beginAtZero: false
              },
              gridLines: {
                display: false,
                drawBorder: false
              }
            }]
          }
        }
      }
    },
    created(){
      this.init();
    },
    methods:{
      init :function(id){
        if(this.styleChart != undefined && this.styleChart!=""){
          this.chartstyle.height= this.styleChart;
        }
        if(this.component != undefined && this.component=="piechart"){
          this.showPiechart= true;
        }
      },
      setDuration :function(id){
        this.$emit("setDuration",id);
      },
      goToView :function(){
        this.$emit("goToView");
      }
    }
}
</script>

<style scoped>
.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
</style>
