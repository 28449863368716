<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: { 
    options: {
      type: Object
    },
    chartData: {
      type: Object
    },
    update: {
      type: Number
    }
  },
  watch: {
    'update' (to, from) { 
      this.renderChart(this.chartData, this.options)
    }
  },
  beforemounted() {
    this.renderChart(this.chartData, this.options)
    // this.renderChart({
    //   labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
    //   datasets: [
    //     {
    //       backgroundColor: [
    //         '#41B883',
    //         '#E46651',
    //         '#00D8FF',
    //         '#DD1B16'
    //       ],
    //       data: [40, 20, 80, 10]
    //     }
    //   ]
    // }, {responsive: true, maintainAspectRatio: false})
  }
}
</script>
