import Domain from "../domain";
export default {
  methods: {
    getChartData_CardCash: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/total/sales?store=&order_type",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalEarningsChartDataApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/total/earning?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalVisitingApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/total/visitors?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getVisitsChartDataApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/product/visit/avg/time?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalCountConvertionApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/convertion/rate?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalReturningCustomerApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/return/customers?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDispatchListCount: async function (params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/count/dispute/details?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getAvgReturningCustomerApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/avg/value/return/customers?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getBestSellingConversionApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/all/best/sales/product?store=&date_range="+id + "&top=true",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getBadSellingConversionApi: async function(id) {
      try {
        let response = await this.axios.get(
          
          Domain.APP_DOMAIN + "merchant/dashboard/not/sales/product?store=&date_range="+id + "&top=true",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getHighestOrderDataApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/highest/order?store=&date_range="+id + "&top=true",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getLowesetOrderDataApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/lowest/order?store=&date_range="+id + "&top=true",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalAvgOrderValApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/avarage/order?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getTotalEntitiesChartDataApi: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/total/enquires?store=&date_range="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getChartData_PendingOrders: async function(id) {
      try {
        if(id === '-1'){
          id = ""
        }
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/pending/order?store="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getChartData_ProdcutReviews: async function(id) {
      try {
        if(id === '-1'){
          id = ""
        }
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/product/review?store="+id,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getChartData_PieBestProducts: async function(store) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/best/sales/product?store=" + store,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getChartData_PieBestProductsWithOutStore: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/best/sales/product",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOderStatusCount: async function(params) {
      try {
        // console.log(params)
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/details/count?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderList: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/details?" + params,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOderDeliveredFailedCount: async function() {
      try {
        // console.log(params)
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/dashboard/count/dfaild/order?",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOderStatusCountWithStatus: async function(status) {
      try {
        // console.log(status)
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/details/count?" + status,
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
